import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { kn as knPartApi } from "../api/index";
import { tugrug } from "../utils";

const ITEMS_PER_PAGE = 20;

const KnDetail = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [vehicleList, setVehicleList] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { product, s3host } = detail;
  const [filterState, setFilterState] = useState({
    maker: "",
    model: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await knPartApi.get(id);
        setDetail(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await knPartApi.vehicles(id);
        setVehicleList(response);
        setFilteredVehicles(response);
      } catch (err) {
        console.log("Error:::>", err);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    const updatedFilter = { ...filterState, [name]: value };
    setFilterState(updatedFilter);

    const filtered = vehicleList.filter(
      (vehicle) =>
        vehicle.maker
          .toLowerCase()
          .includes(updatedFilter.maker.toLowerCase()) &&
        vehicle.model.toLowerCase().includes(updatedFilter.model.toLowerCase())
    );
    setFilteredVehicles(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredVehicles.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedData = filteredVehicles.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  return (
    <div className="container">
      <Helmet>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={product?.name || "Default Product Title"}
        />
        <meta
          property="og:url"
          content={`https://www.autobrand.mn/kn/${
            product?.id || "default-product-id"
          }`}
        />
        <meta
          property="og:description"
          content={product?.description || "Default product description"}
        />
        <meta
          property="og:image"
          content={
            product?.image
              ? `https://www.autobrand.mn/uploads${product?.image}`
              : "%PUBLIC_URL%/logo.png"
          }
        />
      </Helmet>
      <div className="my-5">
        <div className="row mb-5">
          <div className="col-xs-12 col-sm-6">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="border rounded py-5"
            >
              <img
                src={`${s3host}${product?.image}`}
                style={{ height: 300, width: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              {[
                { label: "Үнэ:", value: tugrug(product?.price) },
                { label: "Код:", value: product?.code },
                { label: "Баркод:", value: product?.barcode },
                { label: "Бренд:", value: product?.brand },
                { label: "Нэр:", value: product?.name },
                { label: "Тайлбар:", value: product?.description },
              ].map((item, index, array) => (
                <React.Fragment key={index}>
                  <span
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff",
                      padding: "8px",
                      borderBottom:
                        index < array.length - 1 ? "1px solid #ccc" : "none",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    {item.label}
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff",
                      padding: "8px",
                      borderBottom:
                        index < array.length - 1 ? "1px solid #ccc" : "none",
                    }}
                  >
                    {item.value}
                  </span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {vehicleList.length > 0 ? (
          <>
            <h5>Таарах боломжтой машинууд:</h5>

            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ width: 70, verticalAlign: "middle" }}
                  >
                    #
                  </th>
                  <th>
                    <input
                      type="text"
                      placeholder="Үйлдвэрлэгч"
                      name="maker"
                      value={filterState.maker}
                      onChange={handleFilterChange}
                      className="form-control"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      placeholder="Загвар"
                      name="model"
                      value={filterState.model}
                      onChange={handleFilterChange}
                      className="form-control"
                    />
                  </th>
                  <th style={{ verticalAlign: "middle" }}>Хөдөлгүүр</th>
                  <th style={{ verticalAlign: "middle" }}>Үйлдвэрлэсэн он</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((vehicle, index) => (
                  <tr key={vehicle.id}>
                    <td className="text-center" style={{ width: 70 }}>
                      {index + 1}
                    </td>
                    <td>{vehicle.maker}</td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.engine}</td>
                    <td>{`${vehicle.min_year} - ${vehicle.max_year} `}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 10,
              }}
            >
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  disabled={currentPage === index + 1}
                  className={`btn ${
                    currentPage === index + 1 ? "btn-primary" : "btn-light"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-muted">Таарах машин олдсонгүй</p>
        )}
      </div>
    </div>
  );
};

export default KnDetail;
